<template>
  <div>
    <section class="dashboardFilterHeader">
      <router-link :to="goTo('operations_troubleshooting')">
        <v-btn
          color="dark"
          text
          small
        >
          <!-- @click="redirectToParent" -->
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
      </router-link>
    </section>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="sessions"
      no-filters
      @filterOption="onFiltersChange"
    >
      <template v-slot:id="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:status="{ item }">
        <div>
          <!-- Add a dot with dynamic color based on status -->
          <status-badge :status="item.status" />
        </div>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">

        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <!-- <template #actionModals>  </template> -->
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    StatusBadge: () => import("@/components/base/Status-Badge.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},

      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("Diagnostic Code"),
          align: "left",
          sortable: false,
          value: "code",
        },
        {
          text: this.$t("Code Description"),
          align: "left",
          sortable: false,
          value: "description",
          showAdmin: true,
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
        },
      ],
      sessions: [
        {
          code: "Connector Lock Failure",
          description: "Failure in locking the connector",
          status: "completed",
        },
        {
          code: "EV Communication Error",
          description: "Communication issue between the EV and the EVSE",
          status: "completed",
        },
        {
          code: "Ground Failure",
          description: "Grounding issue detected",
          status: "completed",
        },
        {
          code: "High Temperature",
          description: "Charger temperature is above safe operating limits",
          status: "completed",
        },
        {
          code: "Internal Error",
          description: "Internal hardware or software failure",
          status: "completed",
        },
        {
          code: "Local List Conflict",
          description: "Conflict detected in the local authorization list",
          status: "completed",
        },
        {
          code: "No Error",
          description: "Charger is functioning without issues",
          status: "completed",
        },
        {
          code: "Over Current Failure",
          description: "Excessive current detected",
          status: "completed",
        },
        {
          code: "Power Meter Failure",
          description: " Issue with the power meter",
          status: "completed",
        },
        {
          code: "Power Switch Failure",
          description: "Power switch failure detected",
          status: "completed",
        },
        {
          code: "Reader Failure",
          description: "RFID reader failure",
          status: "completed",
        },
        {
          code: "Reset Failure",
          description: "Failure to reset the charger",
          status: "completed",
        },
        {
          code: "Under Voltage",
          description: "Voltage below operational limits",
          status: "completed",
        },
        {
          code: "Over Voltage",
          description: "Voltage above operational limits",
          status: "completed",
        },
        {
          code: "Weak Signal",
          description: "Weak communication signal detected",
          status: "completed",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "mdMakes/isLoadingMdMakes",
      meta: "mdMakes/meta",
      list: "mdMakes/listMdMakes",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      if (this.authUser.isAdmin) {
        return [];
      } else {
        return [];
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {},
  // beforeDestroy() {
  //   const params = {
  //     name: "md_makes",
  //     filters: this.options,
  //   };
  //   this.$store.commit("redirect/SET_FILTERS", params);
  // },
  methods: {
    async fetchData() {
      const params = {
        product: this.$route.query.product,
        tenant_id: this.$route.query.tenant_id,
        date: this.$route.query.date,
        dateType: this.$route.query.dateType,
      };
      await this.$store.dispatch("mdMakes/list", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },
    getStatusDotColor(status) {
      if (status.includes("green-dot")) return "dot-green";
      if (status.includes("red-dot")) return "dot-red";

      // Default behavior based on percentage
      const chargeValue = this.getChargeValue(status);
      return chargeValue >= 50 ? "dot-green" : "dot-red";
    },
    goTo(path) {
      const qry = this.$route.query;
      return { name: path, query: qry };
    },
  },
};
</script>
<style lang="sass" scoped>
.dashboardFilterHeader
  display: flex
  align-items: center
  padding: 6px 12px 0
  .headerBarWrap
    align-items: center
    padding: 0px 0

  :deep .filterBtn
    display: none !important
</style>
